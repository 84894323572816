import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { PlayerSettings, PlaylistUpdateResponse } from "@common/SocketEvents";

// Define the shape of your context data

interface AppContextData {
  searchModalOpen: boolean;
  isKaraokeMode: boolean;
  roomId: string;
  recentSearches: string[];
  playlistData: PlaylistUpdateResponse;
  administratorOverride: boolean;
  fontSize: number;
  isPlayerConnected: boolean;
  // Add other fields as needed with their types
}

interface AppContextState {
  data: AppContextData;
  setData: <K extends keyof AppContextData>(
    key: K,
    value:
      | AppContextData[K]
      | ((prevValue: AppContextData[K]) => AppContextData[K])
  ) => void;
}
// Load initial data from localStorage or set default values
const queryParams = new URLSearchParams(window.location.search);
const loadInitialData = (): AppContextData => {
  const localData = localStorage.getItem("appContextData");

  const defaultData: AppContextData = {
    searchModalOpen: false,
    roomId: queryParams.get("roomId") || "defaultRoom",
    fontSize: 14,
    recentSearches: [],
    isKaraokeMode: false,
    administratorOverride: queryParams.get("administratorOverride") === "true",
    isPlayerConnected: true,
    playlistData: {
      lastPlayerUpdate: new Date(),
      playlistQueue: [],
      playerSettings: {
        showIdleScreen: false,
        musicPlayControlLocked: false,
        playlistSelectLock: false,
        playlistSearchLock: false,
        playlistDeleteLock: false,
        autoRemovePlayedTracks: true,
        skipDeleteThreshold: 0,
        askWhoSing: false,
      },
      playerMeta: {
        isPlaying: false,
        isAutoPlay: false,
        currentIndex: -1,
        currentProgress: 0,
      },
    },
  };

  try {
    if (localData) {
      // console.log("Loading Local Storage");
      const parsedData = JSON.parse(localData);

      for (const [key, value] of Object.entries(parsedData)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultData[key] = value;

        if (key === "roomId") {
          defaultData[key] = (queryParams.get("roomId") ||
            value ||
            "defaultRoom") as string;
        }
        if (key === "administratorOverride") {
          defaultData[key] =
            queryParams.get("administratorOverride") === "true" ||
            (value as boolean);
        }
      }
    }
  } catch (e) {
    console.warn("Loading Local Storage Failed");
  }
  return defaultData;
};

const defaultValue: AppContextState = {
  data: {
    isPlayerConnected: true,
    searchModalOpen: false,
    fontSize: 14,
    roomId: queryParams.get("roomId") || "defaultRoom",
    recentSearches: [],
    isKaraokeMode: false,
    administratorOverride: false,
    playlistData: {
      lastPlayerUpdate: new Date(),
      playlistQueue: [],
      playerSettings: {} as PlayerSettings,
      playerMeta: {
        isPlaying: false,
        isAutoPlay: false,
        currentIndex: -1,
        currentProgress: 0,
      },
    },
  },
  setData: () => {},
};

export const AppContext = createContext<AppContextState>(defaultValue);

export const AppContextProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [data, setData] = useState<AppContextData>(loadInitialData());

  useEffect(() => {
    // Function to update localStorage when data changes
    localStorage.setItem("appContextData", JSON.stringify(data));
    // console.log("Save context");
  }, [data]); // Only run this effect when data changes

  // Function to update specific key in the state
  const updateData = <K extends keyof AppContextData>(
    key: K,
    value:
      | AppContextData[K]
      | ((prevValue: AppContextData[K]) => AppContextData[K])
  ) => {
    setData((prevData) => {
      const newValue =
        typeof value === "function" ? value(prevData[key]) : value;
      const newData = { ...prevData, [key]: newValue };

      // console.log(newData.playerSettings);

      return newData;
    });
  };

  // The context value that will be provided to the consumers
  const contextValue = {
    data,
    setData: updateData,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
// Custom hook to access the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};
