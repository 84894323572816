import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { forwardRef, useCallback, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import logo from "../assets/roweyz.png";
import { useAppContext } from "../contexts/App.context";
import SearchModal from "./Search.modal";
import SettingsModal from "./Settings.modal";
import "./TopBar.scss";
import { SEARCH_TOOLTIP_IDLE_TIMEOUT } from "../App.constant";
import BottomBar from "./BottomBar";

interface ContextMenuProps {
  onClose: () => void;
  mouseX: number;
  mouseY: number;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  onClose,
  mouseX,
  mouseY,
}) => {
  const isOpen = Boolean(mouseY);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  return (
    <>
      <Menu
        open={isOpen}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={isOpen ? { top: mouseY, left: mouseX } : undefined}
      >
        <MenuItem
          onClick={() => {
            onClose();
            setSettingsModalOpen(true);
          }}
        >
          Open Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
            window.location.reload();
          }}
        >
          Reload Page
        </MenuItem>
        <MenuItem onClick={onClose}>
          VER: {import.meta.env.VITE_VERSION.toUpperCase()}
        </MenuItem>
      </Menu>
      <SettingsModal
        open={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}
      />
    </>
  );
};

interface TopBarState {}

const TopBar = forwardRef<HTMLElement, TopBarState>(() => {
  const ref = useRef<HTMLElement>(null);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const { data, setData } = useAppContext();
  const [enableKaraokeMode, setKaraokemode] = useState(data.isKaraokeMode);

  const { administratorOverride, isPlayerConnected } = data;
  const { playerSettings } = data.playlistData;

  function clickLogo(): void {
    window.location.reload();
  }

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKaraokemode(event.target.checked);
    setData("isKaraokeMode", !enableKaraokeMode);
    // umami.track("toggle-karaoke-mode", { enabled: !enableKaraokeMode });
  };

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const openContextMenu = useCallback((mouseX: number, mouseY: number) => {
    setContextMenu({ mouseX, mouseY });
  }, []);

  const handleOnDoubleClick = (event: React.MouseEvent) => {
    openContextMenu(event.clientX - 2, event.clientY - 4);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const [idle, setIdle] = useState(false);

  useIdleTimer({
    onIdle: () => setIdle(true),
    onActive: () => setIdle(false),
    onAction: () => setIdle(false),
    timeout: SEARCH_TOOLTIP_IDLE_TIMEOUT,
    throttle: 500,
  });

  return (
    <>
      <AppBar
        ref={ref}
        position="sticky"
        sx={{ background: "rgba(0,0,0,.85)" }}
      >
        <ContextMenu
          mouseX={contextMenu?.mouseX ?? 0}
          mouseY={contextMenu?.mouseY ?? 0}
          onClose={handleClose}
        />
        <Toolbar onDoubleClick={handleOnDoubleClick}>
          <Box
            component={"img"}
            src={logo}
            onClick={clickLogo}
            sx={{
              cursor: "pointer",
              margin: 0,
              padding: 0,
              maxWidth: "100px",
              maxHeight: "30px",
            }}
          />
          {!isPlayerConnected && (
            <Tooltip title="Start the Jukebox App on PC">
              <Typography
                variant="subtitle1"
                sx={{
                  color: "red",
                  paddingLeft: 1,
                  marginLeft: 1,
                  borderLeft: "1px solid rgba(255, 255, 255, 0.12)",
                }}
              >
                🚨
              </Typography>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <FormControlLabel
            sx={{
              marginLeft: (theme) => theme.spacing(1),
            }}
            control={
              <Switch
                disabled={
                  playerSettings?.playlistSearchLock && !administratorOverride
                }
                checked={enableKaraokeMode}
                onChange={handleSwitch}
              />
            }
            label={
              <Typography variant="body1">
                {enableKaraokeMode ? "Karaoke Mode" : "Music Mode"}
              </Typography>
            }
          />
          <Tooltip
            open={idle && !searchModalOpen}
            placement="bottom-start"
            // onClose={handleClose}
            // onOpen={handleOpen}
            title={<Typography variant="body1">Touch here to start</Typography>}
            arrow
          >
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              disabled={
                playerSettings?.playlistSearchLock && !administratorOverride
              }
              aria-label="open drawer"
              onClick={() => setSearchModalOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <SearchModal
        open={searchModalOpen}
        onClose={() => setSearchModalOpen(false)}
      />

      {!searchModalOpen && <BottomBar />}
    </>
  );
});

export default TopBar;
